<template>
  <div>
    <h3 class="label" :for="question.key + '_' + applicant">
      {{ question.text }}
    </h3>

    <div class="form-field">
      <div
        :is="question.type + '-input'"
        :id="question.key + '_' + applicant"
        :error="question.error"
        v-on:updated="update"
        :question="question"
      ></div>

      <aside v-if="question.help">{{ question.help }}</aside>

      <aside
        class="error"
        v-if="question.error && question.error !== '[NO_ERROR]'"
        v-html="question.error"
      ></aside>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question", "applicant"],

  components: {
    "text-input": () => import("./Inputs/TextInput.vue"),
    "phone-input": () => import("./Inputs/PhoneInput.vue"),
    "email-input": () => import("./Inputs/EmailInput.vue"),
    "radio-input": () => import("./Inputs/RadioInput.vue"),
    "date-input": () => import("./Inputs/DateInput.vue"),
    "address-input": () => import("./Inputs/AddressInput.vue"),
    "currency-input": () => import("./Inputs/CurrencyInput.vue"),
  },

  data() {
    return {
      options: [],
      value: null,
    };
  },

  methods: {
    update: function (event) {
      this.value = event.value;

      let data = {
        applicant: this.applicant,
        key: this.question.key,
        value: this.value,
        blur: event.blur,
      };

      console.log("AQ", data);

      this.$emit("updated", data);
    },
  },
};
</script>
